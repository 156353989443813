import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import Launch from './Launch';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'


import reducers from './reducers';
import { Provider } from 'react-redux'
import ReduxThunk from 'redux-thunk';
import 'react-toastify/dist/ReactToastify.css';

import {createStore, applyMiddleware} from 'redux';
//import store from './store'

React.icons = icons
const _store = createStore(reducers,applyMiddleware(ReduxThunk));
ReactDOM.render(
  <Provider store={_store}>
    <Launch/>
  </Provider>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
