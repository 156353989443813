const initialState = {
    sidebarShow: 'responsive'
  };
const storestate = (state = initialState, { type, ...rest }) => {
    switch (type) {
      case 'set':
        return {...state, ...rest }
      default:
        return state
    }
  };
export default storestate;