const INITIAL_STATE =
{
    users : null,
    storeData : null,
    totalpages: 1,
    updateStatus:'',
    admin: null,
    temp:[]
};
var {USERS,STOREDATA,UPDATE_USER,LOGIN_ADMIN, USERS_TEMP} = require('../actions/type');
export default (state=INITIAL_STATE,action)=>
{
    switch(action.type)
    {
        case USERS:
            return{...state , users: action.payload, totalpages: action.totalpages, storeData:action.storeData};
        case USERS_TEMP:
            return {...state, temp:action.payload}
        case STOREDATA :
            return({...state, storeData: action.payload});
        case UPDATE_USER:
            return({...state,updateStatus:action.payload});
        case LOGIN_ADMIN:
            return({...state,admin:action.payload});
        default:
            return state ;
    }
};