import React, { Component } from 'react';
import firebase from 'firebase';
import App from './App';


class Launch extends Component {
 
    UNSAFE_componentWillMount()
    {
      var firebaseConfig = {
        apiKey: 'AIzaSyDGaogHaBBFBXKvvqzWaBCtzCvR7ig1lKE',
        authDomain: 'weddingblizz-cdf0a.firebaseapp.com',
        databaseURL: 'https://weddingblizz-cdf0a.firebaseio.com',
        projectId: 'weddingblizz-cdf0a',
        storageBucket: 'weddingblizz-cdf0a.appspot.com',
        messagingSenderId: '1002047503135',
        appId: '1:1002047503135:web:adfbca7c73b5c4ea1a8e2a',
        measurementId: 'G-PH9LE77SJ1'
      };
      if(firebase.app.length!=0)
      {
        firebase.initializeApp(firebaseConfig);
      }
      
    }
  render() {
  return (<div><App/></div>);
  }
}

export default Launch;
